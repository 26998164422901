<template>
  <div class="videoRoom">
    <!-- <div> -->
    <!-- 左视频 -->
    <div class="room-left">
      <div class="room-title">
        <div class="flex-c">
          <div class="elp title">
            {{ detail.title }}
          </div>
          <div class="time">
            <i class="iconfont icon-bofangzhongbeifen" />
            直播时间：{{ detail.startTime | format('yyyy.MM.dd HH:mm') }}
          </div>
        </div>
        <div class="detail-ter flex-c">
          <div class="ter-list">
            <div class="terbox">
              <el-image class="ter-img" :src="liveDetail ? liveDetail.tImg : ''" fit="cover" />
            </div>
            <span class="ter">讲师：{{ liveDetail ? liveDetail.terName : '' }}</span>
          </div>
        </div>
      </div>
      <!-- 视频上方提示 -->
      <div v-if="tipsShow" class="room-Top">
        <!-- 左侧提示 -->
        <div class="wrapTopsCenTopL">
          <img src="@/assets/img/kecheng/tipsIcon.png">
          <span>
            课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。
          </span>
        </div>
        <!-- 右侧关闭 -->
        <div class="wrapTopsCenTopR" @click="tipsShow = false">
          <span>关闭</span>
          <img src="@/assets/img/kecheng/classIcon1123.png">
        </div>
      </div>
      <!-- 视频模块： -->
      <div v-if="!aliVideoUrl" id="playbackPanel" :class="fullScreenInfo ? 'full_screen' : ''">
        <div class="iconfont icon-quanping1" @click="fullScreen" />
        <div class="playbackRate_box">
          <div class="playbackRate_btn">
            倍速
            <div class="playbackRate_item_box">
              <div v-for="item in playbackRateList" :key="item.value" :class="times == item.value ? 'on' : ''"
                class="playbackRate_item" @click="changePlaybackRate(item.value, 'cc')">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div id="logo">
          <img :src="copyright.videoLogo" style="object-fit: contain">
        </div>
      </div>
      <div v-else id="J_prismPlayer">
        <div id="logo">
          <img :src="copyright.videoLogo" style="object-fit: contain">
        </div>
        <div class="playbackRate_box">
          <div class="playbackRate_btn">
            倍速
            <div class="playbackRate_item_box">
              <div v-for="item in playbackRateList" :key="item.value" :class="times == item.value ? 'on' : ''"
                class="playbackRate_item" @click="changePlaybackRate(item.value, 'ali')">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 当只有一个时样式 -->
      <div v-if="dottingList.length == 1" class="videoRBI isBottom">
        <div class="videoRBIContent">
          <div id="videoRBIBox" class="videoRBIBox">
            <div v-for="(item, index) in dottingList" :key="index" class="contentItem onceItem">
              <div class="img_box" @click="jumpTime(item, index)">
                <img class="itemImg" :src="item.coverUrl">
                <!-- <div class="nowPlaying" v-show="item.id == dottingId">
                  正在播放
                </div> -->
                <div v-show="item.id == dottingId" class="red_borders" />
                <div class="dottingBox">
                  <!--  -->
                  <div v-if="item.showTime" class="dottingTime">
                    {{ item.showTime.slice(3, 8) }}
                  </div>
                </div>
              </div>
              <div class="name_box">
                <div class="dotting_names">
                  {{
                    item.knowledgeList && item.knowledgeList[0] && item.knowledgeList[0].name
                    ? item.knowledgeList[0].name
                    : ''
                  }}
                </div>
                <div class="onceBtn">
                  <div class="item_btn">
                    <img class="item_img" src="@/assets/img/Home/Vector2.png" alt="">
                    <span class="btn_name" @click="openOrClose(item)">{{
                      item.isOpen ? '收起知识点' : '展开知识点'
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-if="item.isOpen" class="dsj" />
            </div>
            <div class="nomore">
              <img src="@/assets/img/kecheng/nomore.png" alt="">
              <span>暂无更多知识点~</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 视频打点 -->
      <!-- && (classProductLine != 3 || status != 0) -->
      <div v-else-if="dottingList.length > 1" class="videoRBI">
        <div class="arrow" @click="leftArrow">
          <i class="iconfont icon-xiangzuo" />
        </div>
        <div class="videoRBIContent">
          <div id="videoRBIBox" class="videoRBIBox">
            <div v-for="(item, index) in dottingList" :key="index" class="contentItem">
              <div class="img_box" @click="jumpTime(item, index)">
                <img class="itemImg" :src="item.coverUrl">
                <!-- <div class="nowPlaying" v-show="item.id == dottingId">
                  正在播放
                </div> -->
                <div v-show="item.id == dottingId" class="red_border" />
                <div class="dottingBox">
                  <!--  -->
                  <div class="dottingTime">{{ item.showTime }}</div>
                </div>
              </div>
              <div class="name_box">
                <div class="dotting_name">
                  {{
                    item.knowledgeList && item.knowledgeList[0] && item.knowledgeList[0].name
                    ? item.knowledgeList[0].name
                    : ''
                  }}
                </div>
                <div class="item_btn">
                  <img class="item_img" src="@/assets/img/Home/Vector2.png" alt="">
                  <span class="btn_name" @click="openOrClose(item)">{{
                    item.isOpen ? '收起知识点' : '展开知识点'
                  }}</span>
                </div>
              </div>
              <div v-if="item.isOpen" class="dsj" />
            </div>
          </div>
        </div>
        <div class="arrow" @click="rightArrow">
          <i class="iconfont icon-xiangyou" />
        </div>
      </div>
    </div>
    <!-- 右头像/聊天 -->
    <div class="room-right">
      <!-- 讲师头像 -->
      <div v-if="!aliVideoUrl" id="playbackPlayer" />
      <!-- v-if="!aliVideoUrl"  -->
      <!-- 这里高度都不太一样 -->
      <div class="course_box" :class="{
        course_box1: !aliVideoUrl && dottingList.length > 1,
        course_box2: !aliVideoUrl && dottingList.length <= 0,
        course_box5: !aliVideoUrl && dottingList.length == 1,
        course_box3: aliVideoUrl && dottingList.length > 1,
        course_box4: aliVideoUrl && dottingList.length <= 0,
        course_box6: aliVideoUrl && dottingList.length == 1,
      }">
        <div class="title_box"><i class="iconfont icon-liebiaomoshi m-r-11" /> 课程列表</div>
        <div class="course_list" :style="dottingList.length > 0 || aliVideoUrl ? '' : 'height: 280px'">
          <div v-for="(item, index) in PlaybackPcList" :key="index" class="course_item"
            :class="showId == item.id ? 'on' : ''" @click="gkcb(item)">
            <div class="title_block">
              <i v-if="showId == item.id" class="iconfont icon-bofangzhongbeifen m-r-11" />
              <span class="isTitle">{{ item.title }}</span>
            </div>
            <div v-if="showId == item.id &&
              (item.isHasContantJY == 'true' || item.isHasContant == 'true' || item.isHasDoc == 'true')
              " class="btn_box">
              <div v-if="item.isHasContantJY == 'true'" class="btn_item" @click.stop="goData(item, 4)">
                随堂讲义
              </div>
              <div v-if="item.isHasContant == 'true'" class="btn_item" @click.stop="cgeckTask(item)">
                随堂测试
              </div>
              <div v-if="item.isHasDoc == 'true'" class="btn_item" @click.stop="goData(item, 2)">
                随堂资料
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  <livebo-fang-chater
        class="room"
        :playid="showId"
        :id="courseId"
        :classId="classId"
        :IsPurchase="IsPurchase"
        :classProductLine="classProductLine"
      /> -->
    </div>
    <!-- </div> -->
    <div v-if="!this.IsPurchase && !(this.isLive == 3 && this.isTrial)" class="haveNot" @touchmove.stop.prevent="stop()">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png">
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click.stop="goBack()">
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>

    <ratingPopup :show="ratingPopupShow" :type="1" :course-id="courseId" :chapter-id="showId" :teacher-id="teacherId"
      :is-purchase="IsPurchase" @close="closeRatingPopup" />
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ''
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import Vue from 'vue'
import ratingPopup from '@/components/ratingPopup.vue'
import { getLbClassCourseById, getvideoSegmentList } from '@/api/home'
import { getInfo, getDomain, getToken } from '@/api/cookies'
import { Know, playAuth, courseCheckPlay, judgeIsComment } from '@/api/know'
const know = new Know()
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import liveboFangChater from '@/components/Know/liveboFangChater.vue'
import { newWebSocket } from '@/common/utils/newWebSocket.js'
export default {
  components: { liveboFangChater, ratingPopup },
  props: [
    'detail',
    'isLive',
    'userid',
    'roomid',
    'recordid',
    'courseId',
    'classId',
    'showId',
    'type',
    'ccConfig',
    'IsPurchase',
    'classProductLine',
    'status',
    'aliVideoUrl',
    'teacherId',
    'ccLiveid',
    'isTrial',
    'backAudition',
    'chapterName',
    'typekc',
    'PlaybackPcList'
  ],
  // computed: {
  //   get
  // },
  /* type 1 直播  2录播 录播  3公开*/
  data() {
    return {
      copyright: {}, // 机构信息

      tipsShow: true,
      // 直播 用户名称
      viewername: '',
      // 公聊
      msgList: [],
      // 输入框
      my_msg: '',
      timer: null,
      liveInfo: {},
      liveDetail: {
        userid: '',
        roomid: '',
        recordid: ''
      }, // 课程详情
      /* 学习记录 */
      setIntervals: null,
      histor: 0, // 历史播放时间
      // intervalTimer: null,
      playauth: null, // 阿里加密播放参数
      liveTime: 0,
      firstPlayFlag: true,
      player: null,
      timeStamp: null,
      watchTime: 0,
      playbackRateList: [
        { name: '0.5X', value: 0.5 },
        { name: '1.0X', value: 1 },
        { name: '1.5X', value: 1.5 }
      ],
      times: 1,
      fullScreenInfo: false,
      isfullscreen: false,
      ratingPopupShow: false,
      dottingList: [], // 打点视频列表
      dottingId: null, // 选中的打点ID
      finishTime: null, // 选中的打点视频时长 + 已播放时长
      progressMarkers: [], // 进度条打点列表
      isOpen: false,
      currentData: {},
      judgeCommen: null,
      lookDeatil: null,
      showLookDetail: false,
      goBack: false
    }
  },
  watch: {
    isfullscreen() {
      if (!this.isfullscreen) {
        this.fullScreenInfo = false
      }
    }
  },
  async beforeDestroy() {
    await this.postLiveTime()
    clearInterval(this.setIntervals)
    if (!this.aliVideoUrl) {
      $.DW.destroy()
    }
    this.stopLearn()
  },
  async created() {
    this.copyright = getDomain()
    this.userInfo = getInfo()
    if (!this.IsPurchase && !(this.isLive == 3 && this.isTrial)) {
      console.log('---不满足播放条件---')
      return
    }

    getLbClassCourseById(
      this.courseId,
      this.classId && this.typekc != 16 ? this.classId : undefined,
      this.classId && this.typekc == 16 ? this.classId : undefined
    ).then((res) => {
      this.liveDetail = res.data.details
    })
    await this.getTIme()
    if (this.aliVideoUrl) {
      this.aliPlay()
    } else {
      this.ifCcConfig()
    }
    this.getvideoSegmentList()
  },

  mounted() {
    // 添加监听事件
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))

    document.addEventListener('fullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    document.addEventListener('mozfullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    document.addEventListener('webkitfullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    document.addEventListener('msfullscreenchange', () => {
      this.isfullscreen = !this.isfullscreen
    })
    this.onbeforeunload()

    // source  终端 1 PC 2 M  3 APP 4 IOS 5  领聚 APP  6  领聚 IOS   7 聚学堂小程序 program   8 领聚教育小程序
    // showId 视频节id
    // type  1直播 2录播 3音频 4图文 5面授

    // 连接ws
    const sub = {
      courseType: 10,
      userid: this.userInfo.id,
      tenantid: this.userInfo.tenantId || null,
      source: 1
    }
    // let data = Vue.prototype.export(sub)
    const url = `/${Vue.prototype.Base64.encode(JSON.stringify(sub))}`

    // 限制只能一个人只能看一个课
    courseCheckPlay({
      courseType: 10,
      userId: this.userInfo.id,
      chapterId: this.showId,
      source: 1
    }).then((res) => {
      if (res.code == 0) {

        newWebSocket.init({
          url: url,
          onopen: (msg, data) => {

          },
          onmessage: (data) => {

          },
          onclose: (data) => {

          }
        })
      } else {
        this.lookDeatil = res.msg
        this.showLookDetail = true
        this.goBack = true
      }
    })
    this.judgeIsComment()
  },
  destroyed() {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    newWebSocket.close()
  },
  methods: {
    closeLook() {
      this.showLookDetail = false
      if (this.goBack) {
        window.close()
      }
    },
    // 是否评论过
    judgeIsComment() {
      judgeIsComment({
        stuId: this.userInfo.id,
        type: '1',
        courseId: this.courseId,
        chapterId: this.showId,
        sectionId: ''
      }).then((res) => {
        this.judgeCommen = res.data
      })
    },
    /* 添加学习记录 */
    async addLearn() {

      this.timeStamp = new Date().getTime()
      const data = {
        courseType: 10,
        userid: this.userInfo.id,
        tenantid: this.userInfo.tenantId || null,
        classId: this.classId,
        chapterId: this.showId,
        timeStamp: this.timeStamp,
        chapterName: this.chapterName,
        source: 1
      }

      newWebSocket.sendMessage(JSON.stringify(data))
    },
    // 视频暂停发送ws学习记录暂停调用
    stopLearn() {

      const data = {
        courseType: 10,
        userid: this.userInfo.id,
        timeStamp: this.timeStamp,
        tenantid: this.userInfo.tenantId || null,
        chapterId: this.showId,
        classId: this.classId,
        chapterName: this.chapterName,
        source: 1
      }
      newWebSocket.sendMessage(JSON.stringify(data))
    },
    beforeunloadHandler(e) {
      // alert("刷新或关闭");

      this.postLiveTime()
    },
    fullScreen() {
      const fullscreenElement = document.fullscreenElement
      const element = document.documentElement
      if (fullscreenElement) {
        /* 退出全屏 */
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.fullScreenInfo = false
      } else {
        /* 进入全屏 */
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen()
        }
        this.fullScreenInfo = true
      }
    },
    async ifCcConfig() {
      if (this.ccConfig) {
        const index = this.ccConfig.indexOf('?')
        if (index != -1) {
          var str = this.ccConfig.substr(index + 1)
          var strs = str.split('&')
          for (var i = 0; i < strs.length; i++) {
            this.liveInfo[strs[i].split('=')[0]] = strs[i].split('=')[1]
          }
        }
      } else {
        this.liveInfo.userid = '41A331E332E32281'
        this.liveInfo.roomid = this.roomid
        this.liveInfo.recordid = this.recordid
      }

      this.$nextTick(function () {
        this.liveStart()
      })
    },
    async aliPlay() {
      await playAuth(this.aliVideoUrl)
        .then((res) => {
          this.playauth = res.data
        })
        .then(() => {
          const that = this
          this.player = new Aliplayer({
            id: 'J_prismPlayer',
            width: '100%',
            vid: this.aliVideoUrl,
            playauth: this.playauth,
            encryptType: 1,
            skinLayout: [
              {
                name: 'bigPlayButton',
                align: 'blabs',
                x: 30,
                y: 80
              },
              {
                name: 'H5Loading',
                align: 'cc'
              },
              {
                name: 'errorDisplay',
                align: 'tlabs',
                x: 0,
                y: 0
              },
              {
                name: 'infoDisplay'
              },
              {
                name: 'tooltip',
                align: 'blabs',
                x: 0,
                y: 56
              },
              {
                name: 'thumbnail'
              },
              {
                name: 'controlBar',
                align: 'blabs',
                x: 0,
                y: 0,
                children: [
                  {
                    name: 'progress',
                    align: 'blabs',
                    x: 0,
                    y: 44
                  },
                  {
                    name: 'playButton',
                    align: 'tl',
                    x: 15,
                    y: 12
                  },
                  {
                    name: 'timeDisplay',
                    align: 'tl',
                    x: 10,
                    y: 7
                  },
                  {
                    name: 'fullScreenButton',
                    align: 'tr',
                    x: 10,
                    y: 12
                  },
                  {
                    name: 'subtitle',
                    align: 'tr',
                    x: 15,
                    y: 12
                  },
                  {
                    name: 'volume',
                    align: 'tr',
                    x: 5,
                    y: 10
                  }
                ]
              }
            ]
          })
          this.player.on('play', () => {

            // if (this.intervalTimer == null) {
            //   this.timeInterval()
            // }
          })
          this.player.on('pause', () => {
            console.log('---暂停--11')
            that.stopLearn()
          })
          this.player.on('playing', function () {
            console.log('---playing--11')
            that.addLearn()
          })
          this.player.on('timeupdate', function () {

            if (that.player.getCurrentTime() > that.finishTime) {
              if (that.dottingId) {
                that.player.pause()
              }
              that.dottingId = null
            }
          })
          this.onbeforeunload()
        })
      const _this = this
      this.player.seek(this.histor)
      this.player.on('ended', function () {
        _this.player.fullscreenService.cancelFullScreen()
        console.log('播放已结束!')
        _this.stopLearn()
      })
    },
    onbeforeunload() {
      const _this = this
      window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
      /*   window.onbeforeunload = async function (e) {
        if (_this.$route.name == '直播课播放') {
          await _this.postLiveTime()
          _this.cleanTimeInterval()
          e = e || window.event
          // 兼容IE8和Firefox 4之前的版本
          if (e) {
            e.returnValue = '关闭提示1111'
          }
          // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
          return '关闭提示222'
        } else {
          window.onbeforeunload = null
        }
      } */
    },
    timeInterval() {
      let currTime, rate
      if (this.aliVideoUrl) {
        const J_prismPlayer = document.querySelector('#J_prismPlayer')
        this.liveTime = Math.floor(this.player.getCurrentTime())
        currTime = Math.floor(this.player.getCurrentTime() * 1000)
        rate = J_prismPlayer.querySelector('video').playbackRate
      } else {
        this.liveTime = Math.floor($.DW.getPlayerTime())
        currTime = Math.floor($.DW.getPlayerTime() * 1000)
        rate = $('#playbackVideo').get(0).playbackRate
      }
      rate = parseFloat(rate)
      if (!this.IsPurchase && this.isTrial && this.liveTime >= this.backAudition) {
        console.log('试听结束')
        this.$emit('isTrialEnd')
      }
      // this.getLbClassCourse(currTime, rate)
      // this.intervalTimer = setTimeout(this.timeInterval, 1000 /* Math.round((1000 - 100) / rate) */)
    },
    // cleanTimeInterval() {
    //   clearTimeout(this.intervalTimer)
    //   this.intervalTimer = null
    // },
    /* 添加播放历史 */
    async postLiveTime() {
      await know
        .addStudentLearningPlaybackRecords(
          1, // 张永涛说回放属于直播类型
          this.userInfo.id,
          this.courseId,
          this.showId,
          Math.round(this.liveTime)
        )
        .then((res) => {

        })
    },
    /* 获取播放历史 */
    async getTIme() {
      await know
        .getStudentLearningPlaybackRecords(1, this.userInfo.id, this.courseId, this.showId)
        .then((res) => {
          if (res.code == 0) {
            if (res.msg && res.msg.duration) {
              this.histor = res.msg.duration
              /* $.DW.seek(this.histor); */
            }
          }
        })
    },
    stop() {
      return
    },
    // 初始化
    async liveStart() {

      const that = this

      setTimeout(() => {
        $.DW.config({
          liveid: that.ccLiveid,
          userId: that.liveInfo.userid,
          roomId: that.liveInfo.roomid, // 直播间Id
          recordId: that.liveInfo.recordid, // 直播回放Id
          isH5play: true,
          fastMode: true,
          viewername: this.userInfo.userName,
          viewertoken: JSON.stringify({
            id: that.showId,
            buy: that.IsPurchase ? 1 : 0
          })
        })
        // 动态显示登陆名
        window.onload = function () {
          // that.getLbClassCourse()
        }
        function on_cc_login_error() {
          console.log('登录失败')
        }

        function on_cc_login_success(data) {
          console.log('登录成功')
        }
        // that.getLbClassCourse();
      }, 100)
      // $.DW.isShowBar(1);
      window.on_cc_live_chat_msg_sync = function (msg) {
        // that.msgList.push(msg);
        that.msgList = that.msgList.concat(msg)
        that.$forceUpdate()
        that.scrollbottom()
      }
      window.on_cc_live_player_load = () => {
        setTimeout(() => {
          if (that.firstPlayFlag) {
            $.DW.seek(that.histor)
            that.firstPlayFlag = false
          }
        }, 1000)
      }
      window.on_player_start = () => {
        // 直播开始
        console.log('--start')
        // if (this.intervalTimer == null) {
        // this.timeInterval()
        this.addLearn()
        const playbackVideo = document.querySelector('#playbackVideo')
        playbackVideo.setAttribute('controlsList', 'noplaybackrate ')
        // }
      }
      window.on_spark_player_pause = () => {
        // 播放暂停。
        console.log('--暂停')
        // this.cleanTimeInterval()
        this.stopLearn()
      }
      window.on_spark_player_resume = () => {
        // 恢复播放
        console.log('恢复播放')
        // if (this.intervalTimer == null) {
        //   this.timeInterval()
        // }
      }
      window.on_spark_player_end = () => {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.fullScreenInfo = false
        $.DW.switchFullScreen(false)
        // 播放停止
        this.stopLearn()

        // this.cleanTimeInterval()
      }
      window.on_cc_live_sharestream_end = () => {
        console.log('结束分享流')
      }
      /* if (this.status == 0 && this.classProductLine == 3) {
        $.DW.isShowBar(1);
      } */
      this.timeCHange()
    },
    timeCHange() {
      this.postLiveTime()
      /*  setInterval(() => {
        this.timeCHange();
      }, 1000 * 60); */
    },
    scrollbottom() {
      this.$nextTick(() => {
        // this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
      })
    },
    changePlaybackRate(val, type) {
      if (type == 'cc') {
        this.times = val
        $.DW.playbackRate(val)
      } else if (type == 'ali') {
        this.times = val
        this.player.setSpeed(val)
      }
    },

    closeRatingPopup() {
      this.ratingPopupShow = false
    },

    /* 打点视频列表 */
    async getvideoSegmentList() {
      const data = {
        parentId: this.showId,
        videoType: 'liveCourses'
      }
      await getvideoSegmentList(data).then((res) => {
        res.data.forEach((item) => {
          this.$set(item, 'isOpen', false)
        })
        this.dottingList = res.data

        /* this.dottingList.map(item => {
          this.$set(item, 'nowPlaying', false)
        }) */
        res.data.forEach((item) => {
          const time = item.startTime.split(':')
          const hour = time[0]
          const minute = time[1]
          const second = time[2]
          const playTime = Number(hour * 60 * 60) + Number(minute * 60) + Number(second)
          const obj = {
            offset: playTime,
            isCustomized: true,
            text: item.knowledgeList[0]?.name,
            imgUrl: item.coverUrl
          }
          this.progressMarkers.push(obj)

          const lengthTime = Number(item.lengthTime)
          const h = Math.floor(lengthTime / 60 / 60)
          const m = Math.floor((lengthTime - h * 60 * 60) / 60)
          const s = lengthTime - h * 60 * 60 - m * 60
          item.showTime = `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
        })
      })
    },
    /* 点击打点视频跳转播放进度 */
    jumpTime(item, index) {
      const lengthTime = Number(item.lengthTime)
      this.dottingId = item.id
      if (this.player) {
        this.player.seek(this.progressMarkers[index].offset)
        this.finishTime = Math.floor(this.player.getCurrentTime() + lengthTime)
      } else {
        $.DW.seek(this.progressMarkers[index].offset)
        this.finishTime = Math.floor($.DW.getPlayerTime() + lengthTime)
        this.timer = setInterval(() => {
          if ($.DW.getPlayerTime() > this.finishTime) {
            if (this.dottingId) {
              $.DW.play()
            }
            this.dottingId = null
            clearInterval(this.timer)
          }
        }, 1000)
      }
    },

    /* 视频打点部分点击左箭头 */
    leftArrow() {
      if (this.marginLeft > -1) {
        return
      } else {
        const videoRBIBox = document.getElementById('videoRBIBox')
        videoRBIBox.style.left = this.marginLeft + 168 + 'px'
        this.marginLeft += 168
      }
    },
    /* 视频打点部分点击右箭头 */
    rightArrow() {
      const videoRBIBox = document.getElementById('videoRBIBox')
      const videoRBIBoxWidth = videoRBIBox.clientWidth
      if (videoRBIBoxWidth - 1000 > Math.abs(this.marginLeft)) {
        this.marginLeft -= 168
        videoRBIBox.style.left = this.marginLeft - 168 + 'px'
      }
    },

    // 展开知识点
    openOrClose(item) {
      if (item.id == this.currentData.id) {
        item.isOpen = !item.isOpen
        this.isOpen = !this.isOpen
        this.$emit('isOpen', this.isOpen, item.id)
      } else {
        item.isOpen = true
        this.isOpen = true
        this.currentData.isOpen = false
        this.$emit('isOpen', this.isOpen, item.id)
      }
      this.currentData = item
    },

    // 观看重播按钮  切换课程章节播放
    gkcb(v) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        // this.$message.error("尚未购买，请先去购买课程！");
        this.dialogVisible = true
        return
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }
      if (v.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (v.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }

      const sub = {
        id: v.id,
        classId: this.classId,
        startTime: v.startTime,
        classProductLine: this.classProductLine,
        liveId: v.liveId,
        chapterName: v.title,

        typekc: this.typekc
      }
      for (const key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key]
        }
      }
      const data = Vue.prototype.export(sub)
      this.$router.push(`/oneToOneLive?${data}`)
    },
    /* 随堂资料 */
    goData(item, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true
        return
      }
      window.open(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`, '_blank')
    },
    // 课堂作业
    cgeckTask(item) {
      let sId = item.sId
      let title = item.title
      if (this.ifClass) {
        title = item.contactList[0].sjName
        sId = item.contactList[0].cContactid
      }
      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            title,
            sId,
            2,
            this.ifClass ? 1 : 2,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId,
            this.cardCode
          )
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 视频上方提示
.room-Top {
  width: 100%;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  z-index: 99;

  // 左侧提示
  .wrapTopsCenTopL {
    width: auto;
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin: 0px 10px 0px 10px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f15817;
    }
  }

  // 右侧关闭
  .wrapTopsCenTopR {
    width: auto;
    height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a8abbe;
    }

    img {
      width: 11px;
      height: 11px;
      margin: 0px 10px 0px 5px;
    }
  }
}

/* 讲师 */
.detail-ter {
  // width: 100%;
  // height: 58px;
  // background: #383838;
  // border-radius: 8px 8px 8px 8px;
  // opacity: 1;
  // padding: 0px 20px;
  // .ter {
  //   color: #fff;
  // }
}

/* 左视频打点 */
.videoRBI {
  height: 174px;
  // width: calc(100% + 20px);
  width: 100%;
  margin-top: 13px;
  display: flex;
  background-color: #222222;
  padding: 10px;

  .arrow {
    width: 16px;
    height: 146px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    text-align: center;
    line-height: 146px;
    color: #ffffff;
    cursor: pointer;
  }

  .videoRBIContent {
    width: 832px;
    height: 146px;
    margin: 0 8px;
    position: relative;
    overflow: hidden;

    .videoRBIBox {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;

      .nomore {
        display: flex;
        align-items: center;
        width: 376px;
        height: 122px;
        background: #333333;
        border-radius: 8px;
        margin-left: 8px;
        padding-top: 13px;
        padding-left: 37px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #c9c9c9;
        line-height: 27px;

        img {
          width: 110px;
          height: 110px;
          margin-right: 16px;
        }
      }

      .onceItem {
        display: flex;
        width: 456px !important;
        height: 122px !important;
        background: #ffffff;
        border-radius: 8px;
        padding-top: 4px;
        padding-left: 6px;

        .img_box {
          position: relative;
          width: 197px !important;
          height: 111px !important;
          margin-right: 15px;

          img {
            width: 100% !important;
            height: 100% !important;
          }

          .dottingBox {
            position: absolute;
            bottom: 0;
            background: transparent !important;
          }
        }

        .name_box {
          width: 231px !important;
          padding: 0 !important;

          .dotting_names {
            width: 231px !important;
            height: 54px;
            font-size: 16px !important;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 27px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 18px;
          }

          .onceBtn {
            width: 100%;
          }

          .item_btn {
            justify-content: center;
          }
        }
      }

      .contentItem {
        width: 160px;
        height: 146px;
        margin-right: 8px;
        position: relative;

        .img_box {
          width: 160px;
          height: 90px;
          position: relative;

          .itemImg {
            width: 160px;
            height: 90px;
            border-radius: 8px;
            cursor: pointer;
          }

          .nowPlaying {
            width: 64px;
            height: 24px;
            border-radius: 8px 0 8px 0;
            background-color: #ff5e51;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
          }

          .red_borders {
            border: 2px solid #ff5e51;
            border-radius: 8px 8px 8px 8px;
            width: 197px;
            height: 111px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .red_border {
            border: 2px solid #ff5e51;
            border-radius: 8px 8px 0 0;
            width: 160px;
            height: 90px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .dottingBox {
            width: 160px;
            height: 24px;
            border-radius: 0 0 8px 8px;
            background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.6) 100%);
            color: #ffffff;
            line-height: 24px;
            font-size: 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            cursor: pointer;
          }
        }

        .name_box {
          background-color: #ffffff;
          width: 160px;
          height: 56px;
          border-radius: 0 0 8px 8px;
          padding: 12px 0 0 12px;

          .dotting_name {
            color: #373a42;
            font-size: 12px;
            width: 132px;
            margin-bottom: 6px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .item_btn {
            display: flex;
            align-items: center;
            cursor: pointer;

            .item_img {
              margin-right: 4px;
              width: 10px;
              height: 10px;
            }

            .btn_name {
              color: #ff5e51;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .title {
    width: 480px;
  }

  .time {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    flex-shrink: 0;
    line-height: 14px;
    margin-left: 19px;

    .iconfont {
      font-size: 14px;
      margin-right: 4px;
    }
  }

  .detail-ter {
    color: #ffffff;

    .ter {
      color: #fff;
    }
  }
}

// 中间视频
.videoRoom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 32px 0px;
  background: #17151a;

  // 左视频
  .room-left {
    width: 880px;
    height: 100%;
    margin-right: 15px;
    position: relative;

    #playbackPanel {
      width: 100% !important;
      height: 495px;
      border-radius: 0px 0px 10px 10px;
      opacity: 1;
      position: relative;
      overflow: hidden;

      .icon-quanping1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }

      .playbackRate_box {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999999;

        .playbackRate_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          width: 80px;
          padding: 5px 0;
          border: 1px solid #fff;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;

          .playbackRate_item_box {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.5);
            // background-color: red;
            width: 80px;
            height: 100px;
            position: absolute;
            right: 0;
            top: -100px;
            border-radius: 10px;

            .playbackRate_item {
              color: #fff;
            }

            .on {
              color: #00a2e9;
            }
          }
        }

        .playbackRate_btn:hover {
          border: 1px solid #00a2e9;
          color: #00a2e9;
          transition: 100ms;
        }

        .playbackRate_btn:hover .playbackRate_item_box {
          display: flex;
        }
      }
    }

    #playbackPanel:hover .playbackRate_box {
      display: block;
    }

    #J_prismPlayer {
      position: relative;
      height: 495px !important;

      .playbackRate_box {
        display: none;
        position: absolute;
        bottom: 0;
        right: 100px;
        width: 100px;
        height: 50px;
        z-index: 999999;

        .playbackRate_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          width: 80px;
          padding: 5px 0;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;

          .playbackRate_item_box {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.5);
            // background-color: red;
            width: 80px;
            height: 100px;
            position: absolute;
            right: 0;
            top: -100px;
            border-radius: 10px;

            .playbackRate_item {
              color: #fff;
            }

            .on {
              color: #00a2e9;
            }
          }
        }

        .playbackRate_btn:hover {
          color: #00a2e9;
          transition: 100ms;
        }

        .playbackRate_btn:hover .playbackRate_item_box {
          display: flex;
        }
      }
    }

    #J_prismPlayer:hover .playbackRate_box {
      display: block;
    }

    .prism-player {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .isBottom {
      width: 100%;
      height: 156px !important;
      padding-top: 15px;
      padding-left: 19px;
      background-color: #fff;
      background: #1f1e24;
      border-radius: 8px;

      .videoRBIContent {
        margin: 0 !important;
      }
    }
  }

  // 右头像/聊天
  .room-right {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    #playbackPlayer {
      width: 275px;
      height: 206px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      margin-bottom: 20px;
    }

    img {
      width: 135px;
      height: 113px;
      margin-top: 26px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6a6a6a;
    }

    .room {
      width: 320px;
      height: 371px;
      background: #383838;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      overflow-y: scroll;
    }

    .course_box1 {
      height: 465px !important;

      // max-height: 465px !important;
      .course_list {
        height: 465px !important;
        // max-height: 465px !important;
      }
    }

    .course_box2 {
      height: 280px !important;

      // max-height: 280px !important;
      .course_list {
        height: 280px !important;
        // max-height: 280px !important;
      }
    }

    .course_box3 {
      height: 692px !important;

      // max-height: 692px !important;
      .course_list {
        height: 692px !important;
        // max-height: 692px !important;
      }
    }

    .course_box4 {
      height: 506px !important;

      // max-height: 506px;
      .course_list {
        height: 506px !important;
        // max-height: 506px !important;
      }
    }

    .course_box5 {
      height: 450px !important;

      // max-height: 450px;
      .course_list {
        height: 450px !important;
        // max-height: 450px !important;
      }
    }

    .course_box6 {
      height: 670px !important;

      // max-height: 670px;
      .course_list {
        height: 670px !important;
        // max-height: 670px !important;
      }
    }

    .course_box {
      width: 275px;
      height: 513px;
      flex: 1;

      .title_box {
        width: 275px;
        height: 48px;
        border-radius: 8px 8px 0 0;
        line-height: 48px;
        padding-left: 14px;
        background-color: #333333;
        color: #ffffff;
      }

      .course_list {
        width: 275px;
        height: 465px;
        font-size: 14px;
        border-radius: 0 0 8px 8px;
        background-color: #1f1e24;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 7px;
          height: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: #333333;
          height: 4px !important;
          border-radius: 4px;
        }

        .course_item {
          min-height: 50px;
          width: 100%;
          line-height: 50px;
          padding-left: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #dddddd;
          border-bottom: 1px solid #313035;
          cursor: pointer;

          .m-r-11 {
            margin-right: 11px;
          }

          .btn_box {
            width: 275px;
            height: 51px;
            background-color: #17151a;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: -15px;

            .btn_item {
              width: 68px;
              height: 23px;
              border: 1px solid #d2d2d2;
              border-radius: 4px;
              text-align: center;
              line-height: 23px;
              font-size: 12px;
              color: #ffffff;
              margin-right: 18px;
            }
          }
        }

        .on {
          .title_block {

            i,
            span {
              color: #ff5e51 !important;
            }
          }
        }
      }
    }
  }

  // 聊天框
  .wrapTopsCenCenRC {
    width: 100%;
    height: 346px;

    // 聊天框顶部
    .wrapTopsCenCenRCa {
      width: 100%;
      height: 40px;
      background: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
    }

    // 聊天框内容
    .wrapTopsCenCenRCb {
      width: 100%;
      height: 260px;
      overflow: auto;

      // overflow-y:scroll;
      // overflow-x:hidden
      // 内容盒子
      .wrapTopsCenCenRCbs {
        width: 85%;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        // 左侧头像
        .wrapTopsCenCenRCbsL {
          width: 32px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        // 右侧昵称/留言
        .wrapTopsCenCenRCbsR {
          width: 85%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b0b0b0;
          }

          p {
            width: 100%;
            height: auto;
            overflow: hidden;
            font-size: 12px;
            margin-top: 5px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }

    // 聊天框底部
    .wrapTopsCenCenRCc {
      width: 100%;
      height: 46px;
      background: #5d5d5d;
      display: flex;
      justify-content: center;
      align-items: center;

      // 内容盒子
      .wrapTopsCenCenRCcs {
        width: 85%;
        height: 30px;
        background: #7a7a7a;
        border-radius: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // 输入框
        input {
          width: 70%;
          height: 100%;
          border: none;
          outline: none;
          background: #7a7a7a;
          color: #ffffff;
        }

        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
        }

        // 发送
        div {
          width: 24%;
          height: 100%;
          background: #a1a1a1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0px 15px 15px 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
          cursor: pointer;
        }
      }
    }

    //隐藏滚动条
    ::-webkit-scrollbar {
      display: none;
      scrollbar-width: none !important;
      -ms-overflow-style: none;
    }
  }

  #logo {
    position: absolute;
    right: 30px;
    bottom: 50px;

    img {
      // width: 100%;
      height: 40px;
    }
  }
}

.full_screen {
  overflow: hidden !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: 9998;
}

::v-deep .cc-video-cover {
  display: none !important;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

.dsj {
  position: absolute;
  bottom: -10px;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  height: 0;
  width: 0;
  left: calc(50% - 10px);
}

.title_block {
  display: flex;

  .isTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}</style>
