<template>
  <div
    class="playerContent"
    style="margin: 0; padding: 0; overflow: hidden"
    data-course-id="2403"
  >
    <div class="live" v-if="openInfo != {}">
      <div class="left">
        <div class="video">
          <div
            id="livePlayer"
            style="width: 100%; height: 100%; position: relative"
          ></div>
        </div>
        <div class="line"><i class="iconfont icon-a-zu335"></i>课程列表</div>
        <div class="list">
          <a
            class="item"
            :class="{ on: id == value.id }"
            v-for="(value, index) in PlaybackPcList"
            :key="index"
            @click="check(value)"
          >
            <div class="item-line"></div>
            <div class="icon">{{ index + 1 }}</div>
            <div class="item-title oneEllipsis">{{ value.title }}</div>
            <div class="tag tag2" v-if="value.isLive == 1">直播中</div>
            <div class="tag tag2" v-else-if="value.isLive == 0">未开始</div>
            <div class="tag tag1" v-else>回放</div>
          </a>
        </div>
      </div>
      <div class="midst">
        <div class="midst-left">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        <div class="doc">
          <div class="doc-title">
            <div class="doc-left">
              <div class="icon">
                <img src="@/assets/img/Home/PlayerPublic-icon1.png" />
              </div>
              <div class="name">{{ detail.title }}</div>
            </div>
          </div>
          <div class="prompt" v-if="showTip">
            <div class="textWrap">
              <div class="img">
                <img src="@/assets/img/Home/prompt-img.png" />
              </div>
              <span>
                课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。
              </span>
            </div>
            <div class="prompt-close" @click="showTip = false">
              关闭
              <span class="iconfont iconguanbi"></span>
            </div>
          </div>
          <!-- CC直播 -->
          <div id="drawPanel" :class="fullScreenInfo ? 'full_screen' : ''">
            <div class="iconfont icon-quanping1" @click="fullScreen"></div>
            <div :id="'liveLog'">
              <img :src="copyright.videoLogo" style="object-fit: contain" />
            </div>
          </div>
        </div>
        <div class="midst-right">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </div>
      <div class="right">
        <div class="right-title">聊天</div>
        <div ref="msgList" class="msg-list" id="msg-list">
          <div v-for="(value, index) in chatList" :key="index">
            <!-- 自己发送 -->
            <div class="msg-item my-msg" v-if="value.username == nickName">
              <div class="name">{{ value.username }}</div>
              <div class="msg">{{ value.msg }}</div>
            </div>
            <!-- 其他人 -->
            <div class="msg-item" v-else>
              <div class="name">{{ value.username }}</div>
              <div class="msg">{{ value.msg }}</div>
            </div>
          </div>
        </div>

        <div class="sendMsg">
          <div class="block">
            <textarea
              placeholder="和大家一起讨论一下吧..."
              id="chatinput"
              v-model="content"
            ></textarea>
            <div class="sendMsg-btn" @click="clickSend">发送</div>
          </div>
        </div>
      </div>
    </div>

    <ratingPopup
      :show="ratingPopupShow"
      :type="1"
      :courseId="courseId"
      :chapterId="showId"
      :teacherId="teacherId"
      @close="closeRatingPopup"
    ></ratingPopup>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span
          >观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span
          >,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>


import { getInfo, getDomain, getToken } from "@/api/cookies"
import ratingPopup from "@/components/ratingPopup.vue"
import format from "date-fns/format"
import { newWebSocket } from "@/common/utils/newWebSocket.js"
import {
  saveArriveRecord,
  courseCheckPlay,
  judgeIsComment,
} from "@/api/know"
import Vue from "vue";
export default {
  components: {
    ratingPopup,
  },
  props: [
    "ccConfig",
    "detail",
    "courseId",
    "showId",
    "classId",
    "classProductLine",
    "teacherId",
    "IsPurchase",
    "chapterName",
    "duration",
    "typekc",
    "type",
    'PlaybackPcList'
  ],
  data() {
    return {
      id: 0,
      content: "",
      nickName: "",
      showTip: true,
      pageNum: 1,
      stateTimes: 0,
      pageSize: 10,
      openInfo: {},
      userInfo: {},
      chatList: [],
      openInfoKe: {},
      player: {},
      timer: null,
      DWLive: null,
      liveInfo: {},
      joinTime: "",
      fullScreenInfo: false,
      isfullscreen: false,
      copyright: null,
      timeStamp: null,
      ratingPopupShow: false,
      judgeCommen: null,
      // 观看视频时长
      watchTime: 0,
      lookDeatil: null,
      showLookDetail: false,
    };
  },
  watch: {
    isfullscreen() {
      if (!this.isfullscreen) {
        this.fullScreenInfo = false;
      }
    },
  },
  created() {
    this.copyright = getDomain();
    document.addEventListener("fullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    document.addEventListener("mozfullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    document.addEventListener("msfullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
  },
  async mounted() {
    this.joinTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");

    this.userInfo = getInfo();
    this.initPublicChat();

    // source  终端 1 PC 2 M  3 APP 4 IOS 5  领聚 APP  6  领聚 IOS   7 聚学堂小程序 program   8 领聚教育小程序
    // showId 视频节id
    // type  1直播 2录播 3音频 4图文 5面授

    // 连接ws
    let sub = {
      courseType: 10,
      userid: this.userInfo.id,
      tenantid: this.userInfo.tenantId || null,
      source: 1
    };

    let url = `/${Vue.prototype.Base64.encode(JSON.stringify(sub))}`;
    console.log("-----ws---", url, sub);
    // 限制只能一个人只能看一个课
    courseCheckPlay({
      courseType: 10,
      userId: this.userInfo.id,
      chapterId: this.showId,
      source: 1,
    }).then((res) => {
      if (res.code == 0) {
        newWebSocket.init({
          url: url,
          onopen: (msg, data) => {
            this.signInRecord()
          },
          onmessage: (data) => {
          },
          onclose: (data) => {
          },
        });
      } else {
        this.lookDeatil = res.msg;
        this.showLookDetail = true;
      }
    });
    this.judgeIsComment();
  },
  async beforeDestroy() {
    // 停止直播
    DWLive.destroy();
    newWebSocket.close();

  },
  methods: {
    closeLook() {
      this.showLookDetail = false;
    },
    // 是否评论过
    judgeIsComment() {
      judgeIsComment({
        stuId: this.userInfo.id,
        type: "1",
        courseId: this.courseId,
        chapterId: this.showId,
        sectionId: "",
      }).then((res) => {
        this.judgeCommen = res.data;
      });
    },

    fullScreen() {
      let fullscreenElement = document.fullscreenElement;
      let element = document.documentElement;
      if (fullscreenElement) {
        /* 退出全屏 */
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.fullScreenInfo = false;
      } else {
        /* 进入全屏 */
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        }
        this.fullScreenInfo = true;
      }
    },
    setLiveInfo(res) {
      this.liveInfo = res;
    },

    check(item) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.showId == item.id) {
        return;
      }
      let sub = {
        id: item.id,
        classId: this.classId,
        classProductLine: this.classProductLine,
        chapterName: item.title,
        typekc: this.typekc,
      };
      let data = Vue.prototype.export(sub);
      if (item.isLive == 0) {
        this.$message.error("课程还未到开播时间");
        return;
      }
      if (item.isLive == 2) {
        this.$message.error("课程直播结束");
        return;
      }
      courseCheckPlay({
        courseType: 10,
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push(`/oneToOneLive?${data}`);
        } else {
          this.lookDeatil = res.msg;
          this.showLookDetail = true;
        }
      });
    },
    // 发送聊天
    clickSend() {
      if (this.content == "") {
        this.$message.error("请输入内容！");
      } else {
        DWLive.sendPublicChatMsg(this.content); // 发送公聊
        this.content = "";
      }
    },
    // 1v1 添加到课记录
    async signInRecord() {
      const param = {
        stuId: this.userInfo.id,
        classesId: this.classId,
        palybackId: this.showId,
        source: 1
      }
      await saveArriveRecord(param)
    },
    // 初始化聊天
    initPublicChat() {
      let token = getToken();
      let that = this;
      if (that.ccConfig != "" && that.ccConfig != null) {
        var s = that.ccConfig;
        var roomidStr = s.split("&")[0];
        var roomid = roomidStr.split("=")[1];
        var useridStr = s.split("&")[1];
        var userid = useridStr.split("=")[1];
      }

      that.nickName = that.userInfo.id + "-" + that.userInfo.nickName;
      DWLive.init({
        userid: userid, //必须参数
        roomid: roomid, //必须参数
        pcH5Live: true,
        viewername: that.nickName,
        viewertoken: JSON.stringify({
          id: that.showId,
          buy: that.IsPurchase ? 1 : 0,
        }),
      });
      // 开始直播
      DWLive.onLiveStart = () => {
        window.location.reload()
        this.signInRecord()
      };
      DWLive.onLiveEnd = () => {
        DWLive.switchFullScreen(false);
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.fullScreenInfo = false;

        if (that.judgeCommen == "false") {
          let fullscreenElement = document.fullscreenElement;
          if (fullscreenElement) {
            /* 退出全屏 */
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
            }
            this.fullScreenInfo = false;
          }
          that.ratingPopupShow = true;
        }
        // that.clearTimeInterval();
      };
      // 接收公聊
      DWLive.onPublicChatMessage = function (j) {
        let msgObg = JSON.parse(j);
        that.showEm(msgObg.msg);
        that.chatList = that.chatList.concat(msgObg);
        // 滚动到底部
        that.$nextTick(function () {
          var msgList = this.$refs.msgList;
          msgList.scrollTo({
            //滚动动画
            top: msgList.scrollHeight,
            behavior: "smooth",
          });
        });
      };

      DWLive.onLiveTime = (res) => this.setLiveInfo(res);
      this.DWLive = DWLive;

    },

    //解析表情
    showEm(str) {
      if (!$.trim(str)) {
        return "";
      }
      str = str.replace(/\</g, "&lt;");
      str = str.replace(/\>/g, "&gt;");
      str = str.replace(/\n/g, "<br/>");
      str = str.replace(
        /\[em_([0-9]*)\]/g,
        '<img src="//view.csslcloud.net/img/em/$1.gif" border="0" />'
      );
      str = str.replace(
        /\[em2_([0-9]*)\]/g,
        '<img src="//view.csslcloud.net/img/em2/$1.png" border="0" />'
      );

      var nmsg = ""; //解析表情
      $.each(str.split(" "), function (i, n) {
        n = $.trim(n);
        if (
          n.indexOf("[uri_") == 0 &&
          n.indexOf("]") == n.length - 1 &&
          n.length > 6
        ) {
          var u = n.substring(5, n.length - 1) + " ";
          nmsg +=
            '<a target="_blank" style="color: #2f53ff" href="' +
            u +
            '">' +
            u +
            "</a>" +
            " ";
        } else {
          nmsg += n + " ";
        }
      });
      return nmsg;
    },

    closeRatingPopup() {
      this.ratingPopupShow = false;
    },
    
  },
};
</script>

<style lang="less" scoped>
.playerContent {
  background-color: #dbdbdb;
  height: 80vh;
  width: 100%;
  .live {
    display: flex;
    height: 100%;
    .left {
      width: 280px;
      height: 100%;
      .video {
        width: 280px;
        height: 187px;
        background-color: #fff;
      }
      .line {
        width: 280px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        color: #27323f;
        background: #f1f7f5;
      }
      .list {
        width: 280px;
        // height: 669px;
        height: calc(80vh - 44px - 200px);
        background: #fff;
        position: relative;
        padding-top: 10px;
        overflow-y: auto;
        overflow-x: hidden;

        .item {
          width: 280px;
          height: 36px;
          background: #ffffff;
          cursor: pointer;
          padding-left: 16px;
          position: relative;
          padding-right: 6px;
          margin-bottom: 5px;
          display: block;

          .item-line {
            height: 36px;
            width: 1px;
            background-color: #dddfdf;
            position: absolute;
            z-index: 1;
            left: 27px;
            top: 12px;
          }

          .icon {
            width: 23px;
            height: 23px;
            border: 1px solid #dfe1e1;
            border-radius: 50%;
            color: #494949;
            font-size: 12px;
            float: left;
            text-align: center;
            line-height: 21px;
            margin-top: 6px;
            z-index: 2;
            background-color: #fff;
            position: relative;
          }

          .item-title {
            color: #494949;
            font-size: 14px;
            line-height: 36px;
            margin-left: 10px;
            float: left;
            width: 149px;
          }

          .tag {
            float: right;
            height: 18px;
            border-radius: 9px;
            text-align: center;
            font-size: 14px;
            line-height: 16px;
            padding: 0 8px;
            margin-top: 9px;
            margin-right: 6px;
          }

          .tag1 {
            border: 1px solid #f57d39;
            color: #f57d39;
          }

          .tag2 {
            border: 1px solid #c9c9c9;
            color: #c9c9c9;
          }

          &:hover {
            background: #00a1e9;
            color: #fff;

            .item-title {
              color: #fff;
            }

            .icon {
              border: 1px solid #fff;
              color: #fff;
              background: #00a1e9;
            }

            .item-line {
              background-color: #fff;
            }

            .tag {
              border: 1px solid #fff !important;
              color: #fff !important;
            }
          }
        }

        .item:hover {
          background: #00a1e9;
          color: #fff;
        }

        .child-item {
          width: 280px;
          height: 36px;
          background: #ffffff;
          cursor: pointer;
          padding-left: 16px;
          position: relative;
          padding-right: 6px;
          margin-bottom: 5px;
          display: block;

          .item-line {
            height: 48px;
            width: 1px;
            background-color: #dddfdf;
            position: absolute;
            z-index: 1;
            left: 27px;
            top: 0;
          }

          .icon {
            width: 11px;
            height: 11px;
            border: 1px solid #dfe1e1;
            border-radius: 50%;
            color: #494949;
            font-size: 12px;
            float: left;
            text-align: center;
            line-height: 21px;
            margin-top: 12px;
            z-index: 2;
            background-color: #fff;
            position: relative;
            margin-left: 6px;
          }

          .item-title {
            color: #494949;
            font-size: 14px;
            line-height: 36px;
            margin-left: 10px;
            float: left;
            width: 149px;
          }

          .tag {
            float: right;
            height: 18px;
            border-radius: 9px;
            text-align: center;
            font-size: 14px;
            line-height: 16px;
            padding: 0 8px;
            margin-top: 9px;
            margin-right: 6px;
          }

          .tag1 {
            border: 1px solid #f57d39;
            color: #f57d39;
          }

          .tag2 {
            border: 1px solid #c9c9c9;
            color: #c9c9c9;
          }

          &:hover {
            background: #00a1e9;
            color: #fff;

            .item-title {
              color: #fff;
            }

            .icon {
              border: 1px solid #fff;
              color: #fff;
              background: #00a1e9;
            }

            .item-line {
              background-color: #fff;
            }

            .tag {
              border: 1px solid #fff !important;
              color: #fff !important;
            }
          }
        }

        .on {
          background: #00a1e9;
          color: #fff;

          .item-title {
            color: #fff;
          }

          .icon {
            border: 1px solid #fff;
            color: #fff;
            background: #00a1e9;
          }

          .item-line {
            background-color: #fff;
          }

          .tag {
            border: 1px solid #fff !important;
            color: #fff !important;
          }
        }
      }
    }
    .midst {
      height: 100%;
      width: calc(100% - 560px);
      padding: 0 10px;
      .doc {
        width: 100%;
        height: calc(100% - 44px);
        float: left;
        position: relative;
        .doc-title {
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .doc-left {
            display: flex;
            align-items: center;
            .icon {
              width: 21px;
              height: 18px;
              margin-right: 8px;
            }
            .name {
              color: #7b8196;
            }
          }
          .goBack-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #00a2e9;
            border-radius: 12px;
            font-size: 12px;
            color: #00a2e9;
            line-height: 22px;
            width: 110px;
            cursor: pointer;
            .btn-icon {
              margin-right: 5px;
            }
          }
        }
        .prompt {
          height: 40px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding: 0 50px;
          position: absolute;
          top: 58px;
          z-index: 99;
          .textWrap {
            display: flex;
            align-items: center;
            color: #f15817;
            .img {
              margin-right: 8px;
            }
          }
          .prompt-close {
            color: #a8abbe;
            cursor: pointer;
          }
        }
        #drawPanel {
          background-color: #ffffff;
          position: relative;
          .icon-quanping1 {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
          }
          #liveLog {
            position: absolute;
            right: 50px;
            bottom: 50px;

            img {
              height: 40px;
            }
          }
        }
      }
    }
    .right {
      width: 280px;
      height: 100%;
      .right-title {
        height: 44px;
        border: 1px solid #eeeeee;
        line-height: 44px;
        text-align: center;
        color: #27323f;
        font-size: 14px;
        background-color: #ffffff;
      }
      .msg-list {
        width: 280px;
        // height: 80%;
        height: calc(100% - 144px);
        padding: 15px;
        background-color: #ffffff;
        overflow-y: auto;
        overflow-x: hidden;
        .msg-item {
          overflow: hidden;
          margin-bottom: 30px;

          .name {
            font-size: 14px;
            color: #343434;
            margin-bottom: 5px;
          }

          .msg {
            max-width: 204px;
            background: #e9e9e9;
            border-radius: 0px 10px 10px 10px;
            font-size: 12px;
            color: #343434;
            padding: 10px;
            float: left;
          }
        }

        .my-msg {
          .name {
            font-size: 16px;
            color: #343434;
            margin-bottom: 5px;
            text-align: right;
          }

          .msg {
            max-width: 204px;
            background: #c6eeff;
            border-radius: 10px 0px 10px 10px;
            font-size: 14px;
            color: #343434;
            padding: 10px;
            float: right;
          }
        }
      }
      .sendMsg {
        height: 80px;
        margin-top: 12px;
        background-color: #ffffff;
        padding: 10px;
        .block {
          width: 260px;
          height: 100%;
          position: relative;
          #chatinput {
            width: 100%;
            height: 100%;
            border: none;
            background-color: #eeeeee;
            border-radius: 10px;
            padding: 10px;
          }
          .sendMsg-btn {
            width: 46px;
            height: 26px;
            border-radius: 4px;
            background-color: #00a2e9;
            text-align: center;
            line-height: 26px;
            color: #ffffff;
            font-size: 14px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.oneEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.full_screen {
  overflow: hidden !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: 99999;
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
