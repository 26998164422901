<template>
  <div class="wrap">
    <!-- 视频内容 -->
    <div class="video-box" v-if="IsPurchase || (isTrial && backAudition > 0 && isLive == 3)">
      <!-- 直播回放 -->
      <oneToOneVideoRoom
        ref="videoRoom"
        v-if="(IsPurchase || (isTrial && backAudition > 0)) && isLive == 3"
        :typekc="typekc"
        :isLive="isLive"
        :isTrial="isTrial"
        :backAudition="backAudition"
        :chapterName="chapterName"
        :startTime="startTime"
        :detail="detail"
        :courseId="courseId"
        :showId="id"
        :classId="classId"
        :type="1"
        :roomid="isLive == 1 ? liveInfo.roomid : this.ccRomid"
        :recordid="isLive == 1 ? liveInfo.recordid : this.recordId"
        :IsPurchase="IsPurchase"
        userid="41A331E332E32281"
        :classProductLine="classProductLine"
        :aliVideoUrl="aliVideoUrl"
        :teacherId="teacherId"
        :ccLiveid="ccLiveid"
        :PlaybackPcList="PlaybackPcList"
        @isOpen="getIsOpen"
        @isTrialEnd="trialEnd" />
      <live
        ref="live"
        v-if="IsPurchase && ccRomid && recordId && isLive == 1 && genre == 2"
        :typekc="typekc"
        :roomid="ccRomid"
        :recordid="recordId"
        :isLive="isLive"
        :startTime="startTime"
        :chapterName="chapterName"
        :detail="detail"
        :courseId="courseId"
        :showId="id"
        :classId="classId"
        :type="1"
        :IsPurchase="IsPurchase"
        userid="41A331E332E32281"
        :classProductLine="classProductLine"
        :teacherId="teacherId"
      />
      <!-- 直播 -->
      <oneToOneCcLive
        v-if="isLive == 1 && IsPurchase"
        :typekc="typekc"
        :ccConfig="ccConfig"
        :detail="detail"
        :courseId="courseId"
        :showId="id"
        :chapterName="chapterName"
        :classId="classId"
        :teacherId="teacherId"
        :classProductLine="classProductLine"
        :IsPurchase="IsPurchase"
        :PlaybackPcList="PlaybackPcList"
        :duration="duration">
      </oneToOneCcLive>
    </div>

    <div class="wrap-play" v-else></div>

    <!-- 选项栏 -->
    <div class="content">
      <!-- 知识点 -->
      <transition name="fade">
        <div v-show="isOpen" class="knowledge_points-box">
          <kowledgePoints ref="kowledgePoints" type="1"></kowledgePoints>
        </div>
      </transition>

      <div class="tabTwo m-t-24">
        <div class="tabTwo-text" @click="NavIndex = 1" :class="NavIndex == 1 ? 'active' : ''" v-if="isLive != 1">
          课程答疑
          <div v-if="NavIndex == 1" class="on"></div>
        </div>
        <div class="tabTwo-text" @click="NavIndex = 2" :class="NavIndex == 2 ? 'active' : ''">
          课程评分
          <div v-if="NavIndex == 2" class="on"></div>
        </div>
      </div>
      <!-- 课程答疑 -->
      <answer-view
        :type="1"
        :status="7"
        :courseId="classId"
        :IsPurchase="IsPurchase"
        :chapterId="id"
        v-if="NavIndex == 1"
      />
      <!-- 课程评分 -->
      <score-evaluation v-if="NavIndex == 2" :Belonging="2" :chapterId="id" :sectionId="id" :type="2"
        :typeId="courseId" :typeNmae="this.detail.title" :IsPurchase="IsPurchase" />
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifHave" width="30%" center>
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">返回上一页</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifEnd" width="30%" center>
      <span>试听结束</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="goBack">返回上一页</el-button> -->
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getPcPlaybackId, selectPlaybackPcList } from '@/api/home'
import LiveRoom from '@/components/Know/liveRoom.vue'
import oneToOneVideoRoom from '@/components/Know/oneToOneVideoRoom.vue'
import live from '@/components/Know/live.vue'
import scoreEvaluation from '@/components/Know/course/scoreEvaluation.vue'
import answerView from '@/components/Know/course/answerView.vue'
import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()
import { Know } from '@/api/know'
let know = new Know()
import format from 'date-fns/format'
import { getLbClassCourse } from '@/api/mykecheng'
import { getInfo, getDomain } from '@/api/cookies'
import oneToOneCcLive from '@/components/Know/oneToOneCcLive.vue'
import kowledgePoints from '@/components/kowledgePoints.vue'

export default {
  components: {
    scoreEvaluation,
    answerView,
    LiveRoom,
    oneToOneVideoRoom,
    live,
    oneToOneCcLive,
    kowledgePoints,
  },
  data() {
    return {
      copyright: {}, //机构信息
      userInfo: {},
      NavIndex: 1,
      id: null,
      chapterName: null,
      classId: null,
      courseId: null,
      coursePackageId: null,
      detail: {},
      isLive: null,
      ccConfig: null,
      ccRomid: null,
      recordId: null,
      IsPurchase: false,
      isTrial: false, // 是否试听
      backAudition: 0, // 试听时长
      ifEnd: false, // 试听是否结束
      ifHave: false,
      liveInfo: {},
      liveshowplaybackList: [],
      startTime: '', //  开始直播时间
      PlaybackPcList: [],
      /* 学习记录 */
      setIntervals: null,
      classProductLine: 0,
      status: 0,
      getExplorer: '', //当前浏览器
      genre: null, // 1 直播 2 伪直播
      aliVideoUrl: null, // 阿里回放vid
      teacherId: null, // 老师ID
      isOpen: false,
      ccLiveid: null,
      timer: null,
      duration: null,
      typekc: null,
    }
  },
  async created() {
    
    this.userInfo = getInfo()
    this.copyright = getDomain()
    this.id = this.$route.query.id
    this.chapterName = this.$route.query.chapterName
    this.startTime = this.$route.query.startTime
    this.classId = this.$route.query.classId
    this.typekc = this.$route.query.typekc
    this.backAudition = this.$route.query.backAudition
    this.isTrial = this.$route.query.isTrial == 1 ? true : false
    this.classProductLine = this.$route.query.classProductLine
      ? Number(this.$route.query.classProductLine)
      : 0
    await this.getDetail()
    await this.getPlaybackPcList()
    if (this.isLive == 1) {
      this.NavIndex = 2
    }
    
  },
  beforeDestroy() {
    // this.getLbClassCourse()
    clearInterval(this.setIntervals)
  },
  methods: {
    trialEnd() {
      this.isTrial = false
      this.$confirm('试听结束', '提示', {
        confirmButtonText: '去购买',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.goOrder()
        })
        .catch(() => { })
    },
    goBack() {
      this.$router.go(-1)
    },
    // 1v1章节详情
    async getDetail() {
      await getPcPlaybackId(this.id).then((res) => {
        this.aliVideoUrl = res.data.details.aliVideoUrl
        this.detail = res.data.details
        this.courseId = res.data.details.kcId
        this.isLive = res.data.details.isLive
        this.ccRomid = res.data.details.ccRomid
        this.recordId = res.data.details.recordId
        this.duration = res.data.details.duration
        this.IsPurchase = res.data.IsPurchase
        this.ccConfig = res.data.details.ccConfig
        if (this.ccConfig) {
          this.cutting()
        }
      })
      this.$forceUpdate()
    },
    // 学友课表
    async getPlaybackPcList() {
      const { data } = await selectPlaybackPcList({
        classesId: this.classId,
        stuId: localStorage.getItem("userId"),
        isPurchase: this.IsPurchase ? 1 : 0 // 1 已购买 0未购买
      })
      this.PlaybackPcList = data
    },
    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(this.courseId, this.userInfo.id, this.detail.title, 2, this.detail.title, this.detail.kind)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    /*  */
    /* https://view.csslcloud.net/api/view/callback?roomid=A3AC4528EB09D2E09C33DC5901307461&userid=41A331E332E32281&recordid=7B6BFF4DAB1FFB8B&liveid=A558277FB7900D6B */
    cutting() {
      let index = this.ccConfig.indexOf('?')
      if (index != -1) {
        let str = this.ccConfig.substr(index + 1)
        let strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
          this.liveInfo[strs[i].split('=')[0]] = strs[i].split('=')[1]
        }
      }
    },
    /* --------学习记录--------------- */
    /* 添加记录 */
    // async getLbClassCourse() {
    //   if (!this.IsPurchase || !this.userInfo) {
    //     return
    //   }
    //   getLbClassCourse(
    //     1,
    //     this.userInfo.id,
    //     undefined,
    //     this.classId ? this.classId : undefined,
    //     this.id,
    //     undefined,
    //     this.courseId
    //   )
    // },

    getIsOpen(isOpen, id) {
      this.isOpen = isOpen
      if (!isOpen) return
      this.$refs.kowledgePoints.getData(id)
    },
  },
}
</script>

<style lang="less" scoped>
.video-box {
  position: relative;

  #liveLog {
    position: absolute;
    right: 310px;
    bottom: 90px;
    z-index: 99999;

    img {
      // width: 100%;
      height: 40px;
    }
  }

  #logo {
    position: absolute;
    right: calc(50% - 610px + 380px);
    bottom: 150px;
    z-index: 99999;

    img {
      // width: 100%;
      height: 40px;
    }
  }
}

.wrap {
  width: 100%;
  height: 100%;

  // 顶部黑块
  .wrapTop {
    width: 100%;
    height: 662px;
    background: #282726;

    // 缩宽65%
    .wrapTops {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;

      // 上方课程名
      .wrapTopsTop {
        width: 100%;
        height: 18px;
        margin: 19px 0px 19px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        // 左侧标题
        .wrapTopsTopL {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          // 图片
          img {
            width: 21px;
            height: 100%;
            margin-right: 11px;
          }

          // 文字标题
          p {
            width: auto;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;

            span {
              color: #ffffff;
            }
          }
        }

        // 右侧返回课程介绍
        .wrapTopsTopR {
          width: auto;
          height: auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;

          // 文字
          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          // 返回图片
          img {
            width: 13px;
            height: 11px;
            margin-right: 5px;
          }
        }
      }

      // 视频内容
      .wrapTopsCen {
        width: 100%;
        height: 577px;

        // 视频上方提示
        .wrapTopsCenTop {
          width: 100%;
          height: 40px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // 左侧提示
          .wrapTopsCenTopL {
            width: auto;
            height: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              width: 16px;
              height: 16px;
              margin: 0px 10px 0px 10px;
            }

            span {
              width: auto;
              height: auto;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #f15817;
            }
          }

          // 右侧关闭
          .wrapTopsCenTopR {
            width: auto;
            height: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;

            span {
              width: auto;
              height: auto;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a8abbe;
            }

            img {
              width: 11px;
              height: 11px;
              margin: 0px 10px 0px 5px;
            }
          }
        }

        // 中间视频
        .wrapTopsCenCen {
          width: 100%;
          height: 536px;
          margin-top: 1px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // 左视频
          .wrapTopsCenCenL {
            width: 75%;
            height: 100%;
            background: #f2f2f2;

            #livePlayer {
              width: 100%;
              height: 100%;
              border: 1px solid #ff4027;
            }

            .prism-player {
              width: 100%;
              height: 100%;
            }

            video {
              width: 100%;
              height: 100%;
            }
          }

          // 右头像/聊天
          .wrapTopsCenCenR {
            width: 25%;
            height: 100%;

            // 讲师头像
            .wrapTopsCenCenRT {
              width: 100%;
              height: 190px;
              background: #ffffff;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;

              #livePlayer {
                width: 100%;
                height: 100%;
              }

              img {
                width: 135px;
                height: 113px;
                margin-top: 26px;
              }

              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6a6a6a;
              }
            }

            // 聊天框
            .wrapTopsCenCenRC {
              width: 100%;
              height: 346px;

              // 聊天框顶部
              .wrapTopsCenCenRCa {
                width: 100%;
                height: 40px;
                background: #464646;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #efefef;
              }

              // 聊天框内容
              .wrapTopsCenCenRCb {
                width: 100%;
                height: 260px;
                overflow: auto;

                // overflow-y:scroll;
                // overflow-x:hidden
                // 内容盒子
                .wrapTopsCenCenRCbs {
                  width: 85%;
                  height: auto;
                  margin: 20px auto;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;

                  // 左侧头像
                  .wrapTopsCenCenRCbsL {
                    width: 32px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }

                  // 右侧昵称/留言
                  .wrapTopsCenCenRCbsR {
                    width: 85%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    span {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #b0b0b0;
                    }

                    p {
                      width: 100%;
                      height: auto;
                      overflow: hidden;
                      font-size: 12px;
                      margin-top: 5px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #ffffff;
                    }
                  }
                }
              }

              // 聊天框底部
              .wrapTopsCenCenRCc {
                width: 100%;
                height: 46px;
                background: #5d5d5d;
                display: flex;
                justify-content: center;
                align-items: center;

                // 内容盒子
                .wrapTopsCenCenRCcs {
                  width: 85%;
                  height: 30px;
                  background: #7a7a7a;
                  border-radius: 15px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  // 输入框
                  input {
                    width: 70%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: #7a7a7a;
                    color: #ffffff;
                  }

                  // 修改输入框预输入文字
                  input::-webkit-input-placeholder {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                  }

                  // 发送
                  div {
                    width: 24%;
                    height: 100%;
                    background: #a1a1a1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0px 15px 15px 0px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // 选项栏
  .warpnav {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: 1px solid #dddddd;

    // 缩宽
    .warpnavs {
      width: 65%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // 知识套餐概述
      span {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }

      // 竖线
      .line {
        width: 1px;
        height: 14px;
        background: #cccccc;
        margin: 0px 40px;
        margin-top: 5px;
      }

      // 转换内容 选中
      .warpnavsCon {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          margin-top: 20px;
        }

        div {
          width: 30px;
          height: 3px;
          margin-top: 14px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
        }
      }

      // 转换内容 未选中
      .warpnavsCons {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-top: 20px;
        }
      }
    }
  }

  // 直播课内容
  .warpCon {
    width: 100%;
    // height: 920px;
    height: auto;
    margin-bottom: 30px;
    background: #f5f6fa;
    overflow: hidden;

    // 缩宽
    .warpCons {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      margin-bottom: 50px;

      .warpConss {
        width: 100%;
        height: auto;

        // 单个内容
        .warpConske {
          width: 100%;
          height: 76px;
          margin-top: 30px;
          background: #ffffff;
          border: 1px solid #ffc3bb;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // 左侧标题
          .warpConskeL {
            width: auto;
            height: 19px;
            margin-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
              width: 21px;
              height: 19px;
              margin-right: 16px;
              cursor: pointer;
            }

            p {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #27323f;
              cursor: pointer;

              span {
                width: auto;
                height: auto;
                margin-left: 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7b8196;
              }
            }
          }

          // 右侧展开/收起
          .warpConskeR {
            width: auto;
            height: 15px;
            margin-right: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;

            img {
              width: 9px;
              height: 7px;
              margin-left: 7px;
            }

            span {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }

        // 单个内容展开项盒子
        .warpConskexq {
          width: 100%;
          height: auto;
          background: #ffffff;
          padding: 20px;

          .warpConskexqs {
            width: 1175px;
            height: 50px;
            background: #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;

            // 左侧
            .warpConskexqsL {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              div {
                width: 4px;
                height: 50px;
                background: #dddddd;
                margin-right: 27px;
              }

              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
              }
            }

            // 右侧
            .warpConskexqsR {
              width: 320px;
              height: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              div {
                width: 90px;
                height: 32px;
                margin-right: 10px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .apple {
      width: 65%;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .appleLeft {
        width: 885px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;

        .appleLefts {
          width: 750px;
          height: auto;
          margin: 60px auto;

          /deep/ img {
            width: 100% !important;
          }
        }
      }

      .appleRight {
        width: 320px;
        height: auto;
        background: #ffffff;

        .appleRightTop {
          width: 280px;
          height: auto;
          margin: 20px auto;

          .appleRightTopa {
            width: 100%;
            height: 20px;
            border-left: 3px solid #009aff;
            font-size: 18px;
            color: #333;
            margin-bottom: 2px;
            padding-left: 8px;
            line-height: 18px;
          }

          .appleRightTopb {
            width: 100%;
            height: 112px;
            border-bottom: 1px solid #eee;
            display: flex;
            justify-content: center;
            align-items: center;

            .appleRightTopbs {
              width: 100%;
              height: 70px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              img {
                margin-right: 10px;
                width: 125px;
                height: 70px;
              }
            }
          }
        }

        .appleRightCen {
          width: 100%;
          height: 20px;
          background: #f5f6fa;
        }

        .appleRightBtm {
          width: 280px;
          height: 200px;
          margin: auto;

          .appleRightBtma {
            margin-top: 20px;
            width: 100%;
            height: 20px;
            border-left: 3px solid #009aff;
            font-size: 18px;
            color: #333;
            margin-bottom: 2px;
            padding-left: 8px;
            line-height: 18px;
          }

          .appleRightBtmb {
            width: 100%;
            height: 144px;
            display: flex;
            margin-top: 30px;

            .appleRightBtmbs {
              width: 50%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;

              img {
                width: 100px;
                height: 100px;
              }

              p {
                width: 100%;
                height: 34px;
                font-size: 14px;
                color: #626262;
                line-height: 34px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.wrap-play {
  height: 617px;
  background: #222222;
}

.content {
  width: 1220px;
  background: #ffffff;
  margin: 20px auto;

  .knowledge_points-box {
    width: 1220px;
    max-height: 580px;
    background-color: #ffffff;
    margin: 20px auto;
    padding: 32px 20px;
    overflow: auto;
  }
}
</style>
